import { createRouter, createWebHistory } from 'vue-router';
import Onepage from './pages/Onepage.vue';

const routes = [
    {
        path: '/',
        name: 'onepage',
        component: Onepage,
    },        
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    { path: '/:pathMatch(.*)*', beforeEnter: (to, from, next) => { next('/notfound') } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
