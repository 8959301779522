<template>
    <div class="surface-0 overflow-hidden">
        <div class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
            <router-link to="/" class="flex align-items-center">
                <img :src="'layout/images/logo.png'" alt="Hiperyon Logo" height="50" class="mr-0 lg:mr-2"><span class="text-900 font-medium text-2xl line-height-3 mr-8">HIPERYON</span>
            </router-link>
            <a class="cursor-pointer block lg:hidden text-700 p-ripple" v-ripple
                v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:92%">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                    <li>
                        <a @click="smoothScroll('#hero')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Home</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#features')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Recursos</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#highlight')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Nossos Serviços</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#contact')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Contatos</span>
                        </a>
                    </li>
                </ul>
                <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                    <div class="mt-3 sm:mt-0">
                        <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
                            <i class="pi pi-twitter text-xl"></i>
                        </a>
                        <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                            <i class="pi pi-facebook text-xl"></i>
                        </a>
                        <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                            <i class="pi pi-github text-xl"></i>
                        </a>
                    </div>                    
                </div>
            </div>
        </div>

        <div class="grid py-4 px-4 lg:px-8 relative" id="hero">
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-900 line-height-2"><span class="font-light block">Somos uma empresa</span>nascida da paixão por fazer ótimos produtos.</h1>
                <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">“Um bom projeto pode decidir a melhor solução para cada caso, economiza capital e oferece soluções úteis ao seu negócio.”</p>
                
            </div>
            <img src="layout/images/screen-1.png" class="bottom-0" alt="hero screen" style="right:10%;">
        </div>
        
        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-8 mb-4">
                    <h2 class="text-900 font-normal mb-2">Principais recursos</h2>
                    <span class="text-600 text-2xl">Conhece alguns dos recursos presentes em nossos sistemas</span>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:200px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Facíl de usar</h5>
                            <span class="text-600">De acordo com o seu projeto e sua necessidade oferecemos uma área administrativa para sites e/ou sistemas. Para que você mesmo possa gerenciar dados e informações a qualquer momento.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:200px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-palette text-2xl text-cyan-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Interface Amigável</h5>
                            <span class="text-600">O nosso foco principal é fornecer ao usuário do site ou sistema uma experiência agradável, intuitiva e amigável ao utilizá-los em seu dia a dia.</span>
                        </div>
                    </div>
                </div>
        
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:200px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(145, 210, 204, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-bluegray-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-id-card text-2xl text-bluegray-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Layout Responsivo</h5>
                            <span class="text-600">Nosso projeto é focado em obter a melhor experiência visual, para que os seus clientes visitem e lembrem do seu site. Além disso, trabalhamos com layouts responsivos.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:200px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(145, 226, 237, 0.2),rgba(160, 210, 250, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-orange-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-star text-2xl text-orange-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Desing Clean</h5>
                            <span class="text-600">Feito a partir de formas simples e cores sólidas, trazendo clareza e harmonia em interfaces, ilustrações, etc.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:200px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(251, 199, 145, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-pink-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-moon text-2xl text-pink-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Layout com Dark Mode</h5>
                            <span class="text-600">O modo escuro tem como objetivo reduzir a exposição à luz azul e ajudar com o cansaço visual que vem com o tempo de tela prolongado.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:200px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-purple-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-eye text-2xl text-purple-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Privacidade</h5>
                            <span class="text-600">A LGPD visa proteger os direitos de liberdade e de privacidade, criando normas a serem seguidas por empresas e governos para a coleta e o tratamento de dados pessoais e de dados sensíveis</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-8 mb-8 p-2 md:p-8" style="border-radius:20px; background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
                    <div class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">
                        <h3 class="text-gray-900 mb-2">Suporte ao Cliente</h3>
                        <span class="text-gray-600 text-2xl">de forma personalizada</span>
                        <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">“Oferecemos todo o suporte do inicio ao fim do seu projeto. Manutenção mensal de sites, criação de sites, hospedagem de sites, otimização de sites no Google, emails personalizados dentre outros serviços.”</p>
                        
                    </div>
                </div>
            </div>
        </div>
            
        <div class="py-4 px-4 lg:px-8 mx-0 my-6 lg:mx-8" id="highlight">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Nossos Serviços</h2>
                <span class="text-600 text-2xl">Tudo realizado com os melhores frameworks do mercado </span>
            </div>

            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-6 bg-purple-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="layout/images/mockup.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <div class="flex align-items-center justify-content-center bg-purple-200" style="width:4.2rem; height:4.2rem; border-radius: 10px;">
                        <i class="pi pi-fw pi-mobile text-5xl text-purple-700"></i>
                    </div>
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Aplicativos e jogos para Android</h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">Utilizamos as mais novas tecnologias do mercado para denvolver Apps com rapidez, agilidade e segurança. Buscando sempre a melhor experiência possível para o cliente, em termos de aparência e usabilidade. </span>
                </div>
            </div>

            <div class="grid my-8 pt-2 md:pt-8">
                <div class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-start align-items-center">
                    <div class="flex align-items-center justify-content-center bg-yellow-200" style="width:4.2rem; height:4.2rem; border-radius:10px;">
                        <i class="pi pi-fw pi-desktop text-5xl text-yellow-700"></i>
                    </div>
                    <h2 class="line-height-1 text-900 text-4xl font-normal">CRIAÇÃO DE SITES E SISTEMAS</h2>
                    <span class="text-700 text-2xl line-height-3 mr-0 md:mr-2" style="max-width:650px;">Divulgue a sua empresa na internet. Criamos seu Website com as melhores tecnologias do mercado para aumentar suas possibilidades de negócios e agregar valor ao seu trabalho.<br/>
                        Desenvolvemos sistemas especializados para atender sua necessidade. Segurança, controle e agilidade geram aumento da sua produção. Criamos seu sistema utilizando tecnologias modernas que garantem o máximo em agilidade, qualidade e performance.</span>
                </div>

                <div class="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 bg-yellow-100 p-0" style="border-radius:8px;">
                    <img src="layout/images/mockup-desktop.png" class="w-11 pt-4" alt="mockup">
                </div>
            </div>
        </div>

        <div class="py-4 px-4 lg:px-8 my-2 md:my-4" id="contact">            
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div class="text-700 text-center">                    
                    <div class="text-900 font-bold text-5xl mb-3">FALE CONOSCO</div>
                    <div class="text-700 text-2xl mb-5">Entre em contato conosco e solicite um orçamento</div>
                    <Button label="CHAMAR NO ZAP" @click="openZap()" icon="pi pi-discord" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
            </div>
        
        </div>

        <div class="py-4 px-4 mx-0 mt-8 lg:mx-12 bg" id="footer" style="background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
            <div class="block-content">
                <div class="">
                    <div class=" px-4 md:px-6 lg:px-8">
                        <div class="py-6 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
                            <div>
                                <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                                    <img :src="'layout/images/logo.png'" alt="footer sections" width="50" height="50" class="mr-2">
                                    <h4 class="font-medium text-3xl text-900">HIPERYON</h4>
                                </div>
                                <div class="mt-2 line-height-3">© 2022 Hiperyon, Inc. All rights reserved</div>
                            </div>
                            <div class="mt-3 sm:mt-0">
                                <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
                                    <i class="pi pi-twitter text-xl"></i>
                                </a>
                                <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                                    <i class="pi pi-facebook text-xl"></i>
                                </a>
                                <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                                    <i class="pi pi-github text-xl"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        openZap(){
            window.open('https://api.whatsapp.com/send?phone=5598988183154','_blank');
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style>
#hero{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EEEFAF 0%, #C3E3FA 100%);
    height:700px;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    #hero{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 530px;
    }
}

@media screen and (min-width: 1300px){
    #hero > img {
        position: absolute;
    }

    #hero > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #hero {
        height: 600px;
    }

    #hero > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #hero > div {
        width: 100%;
    }

    #hero > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>